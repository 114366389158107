.err404{
    text-align: center;
}
.err404 img{
    box-shadow: rgba(125, 132, 138, 0.2) 0px 8px 24px;
    width: 50%;
    border-radius: 5px;
}

@media(max-width: 1000px){
    .err404 img{
        width: 100%;
        margin: 50px 0;
    }
}