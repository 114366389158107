.one-project{
    margin: 10px;
    padding: 4px;
    border: 2px solid rgb(64, 64, 64);
    border-radius: 10px;
    width: 31.96%;
    background-color: rgba(0, 0, 0, 0.5);
}
.projects{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 50px;
}
.projects img{
    width: 100%;
    border-radius: 5px;
}
.projects p{
    font-size: 1.1rem;
    margin: 5px 0;
}
.projects h2{
    color: white;
    padding: 5px 0;
}
.projects a{
    color: #027ab2;
    text-decoration: none;
}

@media(max-width: 1000px){
    .projects{
        flex-direction: column;
        align-items: center;
    }
    .one-project{
        width: 90%;
        margin: 10px;
    }
}