footer{
    display: flex;
    justify-content: space-around;
    background-color: #0c0c0c;
    color: white;
    padding: 20px;
    margin-top: auto;
}
footer h2{
    font-size: 1.3rem;
}
footer .link{
    color: #229dd8;
    text-decoration: none;
}
@media(max-width:500px){
    footer{
        flex-direction: column;
        text-align: center;
    }
}