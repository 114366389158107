nav{
    display: flex;
    justify-content: flex-end;
    padding: 0 5%;
}
nav a{
    margin: 20px;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
}
nav a.active{
    border-bottom: 2px solid white;
}