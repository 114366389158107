.socials{
    color: white;
    text-align: center;
}
.socials h2{
    font-size:3rem;
    margin: 50px 0;
}
.socials>div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.socials>div a{
    border: 3px solid rgb(58, 57, 57);
    border-radius: 7px;
    padding: 5px 10px;
    margin: 5px 0;
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    width: 300px;
}